import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_BASE_URL;

const forgotPassword = (email) => {
  return axios.post(API_URL + "forgotPass", { headers: authHeader() });
};

const ChangePassword = (old_password, new_password, confirm_password) => {
  return axios.post(
    API_URL + "change_password",
    { old_password, new_password, confirm_password },
    { headers: authHeader() }
  );
};

const userObject = {
  forgotPassword,
  ChangePassword
};
export default userObject;
