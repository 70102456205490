import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Snackbar,
  Typography,
} from "@mui/material";
import DocDetailService from "../services/item.service";
import AuthService from "../services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import DetailItemList from "./detailitemlist";

export default function DetialsItem(props) {
  const initialFormData = {
    ItemId: "",
    Doc_id: "",
    IdntNo: "",
    Chln_Qty: 0,
    Recd_Qty: 0,
    Rejected_Qty: 0,
    Accepted_Qty: 0,
    Issue_Qty: 0,
    Rate: 0,
    Value: 0,
    Narration: "",
    Remarks: "",
    HSNCode: "",
  };
  const [inputField, setInputField] = React.useState(initialFormData);
  const [MasterItemData, setMasterItemData] = React.useState({});
  const [selectedItemid, setSelectedItemid] = React.useState(null);
  const [recqty, setRecqty] = React.useState(0);
  const [rejqty, setRejqty] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);
  const [issqty, setIssueQty] = React.useState(0);
  const [rate, setRate] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [Storedata, setStoredata] = React.useState({});
  const [isRecordSaved, setIsRecordSaved] = React.useState("");
  const [hsncode, sethsncode] = React.useState(0);
  const [uom, setuom] = React.useState("");
  const [mode, setMode] = React.useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  let id = 0;
  let DocType = "";
  let MRRName = "";
  let DocDate = "";
  if (location.state && location.state.id) {
    id = location.state.id;
    DocType = location.state.DocType;
    DocDate = location.state.DocDate;
    MRRName = DocType + "/" + location.state.DocNo + "/" + DocDate;
  } else {
    navigate("/storemrrlist");
  }
  const Str_Id = AuthService.getStoreId();
  const handleChange = (evt) => {
    const value = evt.target.value;
    setInputField({
      ...inputField,
      [evt.target.name]: value,
    });
  };

  const handleInputChange = (event, newValue) => {
    setInputField({
      ...inputField,
      ["HSNCode"]: newValue.HSNCode ? newValue.HSNCode : "",
    });
    sethsncode(newValue.HSNCode ? newValue.HSNCode : "");
    setuom(newValue.UOM ? newValue.UOM : "");
    setSelectedItemid(newValue);
  };
  const handleDataFromChild = (data) => {
    getMasterItem();
    if (data.ItemId) {
      const found = MasterItemData.find((obj) => {
        return obj.id === data.ItemId;
      });
      setSelectedItemid(found);
      sethsncode(found.HSNCode);
      setInputField({
        ...inputField,
        ["HSNCode"]: found.HSNCode,
      });
      setuom(found.UOM);
    }
    setInputField(data);
    setIssueQty(data.Issue_Qty);
    setRecqty(data.Recd_Qty);
    setRejqty(data.Rejected_Qty);
    setQuantity(data.Accepted_Qty);
    setRate(data.Rate);
    setTotal(data.Value);
    setMode(data.id);
  };

  const getMasterItem = () => {
    setLoading(true);
    DocDetailService.getMasterItem(Str_Id, DocType, DocDate).then(
      (response) => {
        if (response.data.status) {
          setMasterItemData(response.data.data);
          setLoading(false);
        }
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setMessage(_content);
      }
    );
  };

  React.useEffect(() => {
    getMasterItem();
  }, []);

  React.useEffect(() => {
    setLoading(true);
    DocDetailService.getStoreItemList(id).then(
      (response) => {
        if (response.data.status) {
          setStoredata(response.data.data);
          setLoading(false);
        }
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setMessage(_content);
      }
    );
  }, [isRecordSaved]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      ((DocType === "ISS" || DocType === "REJ") && issqty === 0) ||
      issqty > (selectedItemid && selectedItemid.BalQty)
    ) {
      let q = DocType === "ISS" ? "Issue" : "Reject";
      alert(q + " Qty must be less then Bal Qty.");
      return false;
    }
    if (rate === 0) {
      alert("Rate must be greater than zero.");
      return false;
    }

    if (total === 0) {
      alert("Total must be greater than zero.");
      return false;
    }
    setLoading(true);
    setSeverity("");
    const data = new FormData(event.currentTarget);
    data.append("Doc_id", id); //append the values with key, value pair
    data.append("ItemId", selectedItemid && selectedItemid.id);
    data.append("id", mode);
    DocDetailService.createItem(data).then(
      (response) => {
        setOpen(true);
        setMessage(response.data.msg);
        if (response.data.status === "true") {
          setSeverity("success");
          event.target.reset();
          setIsRecordSaved(response.data.data.id);
          setInputField(initialFormData);
          setRecqty(0);
          setRejqty(0);
          setQuantity(0);
          setRate(0);
          setTotal(0);
          setSelectedItemid(null);
          setMode(0);
          setIssueQty(0);
          setSelectedItemid(null);
          sethsncode();
          getMasterItem();
        } else {
          setSeverity("error");
        }
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const handleDelete = (event) => {
    if (window.confirm("Are you sure want to Delete this Item?") === false) {
      return false;
    }
    setLoading(true);
    setSeverity("");
    DocDetailService.deleteItem(id, mode).then(
      (response) => {
        setOpen(true);
        setMessage(response.data.msg);
        if (response.data.status === "true") {
          setSeverity("success");
          setIsRecordSaved(response.data.data.id);
          setInputField(initialFormData);
          setRecqty(0);
          setRejqty(0);
          setQuantity(0);
          setRate(0);
          setTotal(0);
          setSelectedItemid(null);
          setIssueQty(0);
          setMode(0);
        } else {
          setSeverity("error");
        }
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const handleButtonClick = (params) => {
    navigate("/storemrrlist", {
      replace: true,
      state: { DocType: DocType },
    });
  };

  const handleChhanChange = (event) => {
    let newQuantity = parseFloat(event.target.value);
    setRecqty(newQuantity);
    setRejqty(0);
    setQuantity(newQuantity);
    calculateTotal(newQuantity, rate);
    handleChange(event);
  };

  const handleIssChange = (event) => {
    let newQuantity = parseFloat(event.target.value);
    setIssueQty(newQuantity);
    calculateTotal(newQuantity, rate);
    handleChange(event);
  };

  const handleRetChange = (event) => {
    let newQuantity = parseFloat(event.target.value);
    setQuantity(newQuantity);
    calculateTotal(newQuantity, rate);
    handleChange(event);
  };

  const handleRecChange = (event) => {
    let newQuantity = parseFloat(event.target.value);
    newQuantity = newQuantity > inputField.Chln_Qty ? 0 : newQuantity;
    setRecqty(newQuantity);
    setQuantity(newQuantity - rejqty);
    calculateTotal(newQuantity - rejqty, rate);
    handleChange(event);
  };
  const handleRejChange = (event) => {
    let newQuantity = parseFloat(event.target.value);
    newQuantity = newQuantity > recqty ? 0 : newQuantity;
    setRejqty(newQuantity);
    setQuantity(recqty - newQuantity);
    calculateTotal(recqty - newQuantity, rate);
    handleChange(event);
  };

  const handleRateChange = (event) => {
    const newRate = parseFloat(event.target.value);
    setRate(newRate);
    if (issqty) {
      calculateTotal(issqty, newRate);
    } else {
      calculateTotal(quantity, newRate);
    }
  };

  const calculateTotal = (newQuantity, newRate) => {
    if (!isNaN(newQuantity) && !isNaN(newRate)) {
      const newTotal = newQuantity * newRate;
      setTotal(newTotal);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleReset = () => {
    setInputField({
      ItemId: "",
      Doc_id: "",
      IdntNo: "",
      Chln_Qty: 0,
      Recd_Qty: 0,
      Rejected_Qty: 0,
      Accepted_Qty: 0,
      Issue_Qty: "",
      Rate: 0,
      Value: 0,
      Narration: "",
      Remarks: "",
    });
    setMode(0);
    setRecqty(0);
    setRejqty(0);
    setQuantity(0);
    setRate(0);
    setTotal(0);
    setSelectedItemid(null);
  };
  return (
    <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        {mode ? "Edit" : "Add"} {DocType} Item - [{MRRName}]
      </Typography>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit}
        sx={{
          mt: 1,
          p: 1,
          mb: 1,
          width: "100%",
          boxShadow: 2,
          borderRadius: 1,
          border: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <Autocomplete
              id="ItemId"
              name="ItemId"
              required
              disableClearable
              options={MasterItemData} // Provide the fetched data as options
              getOptionLabel={(option) => option.ItemDesc || ""} // Define how to display the option label
              value={selectedItemid}
              onChange={handleInputChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField {...params} label="Item Name" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <TextField
              id="UOM"
              name="UOM"
              label="UOM"
              fullWidth
              autoComplete="off"
              variant="outlined"
              value={uom}
              inputProps={{ readOnly: true }}
              InputLabelProps={{
                shrink: uom !== "", // This will shrink the label when there's a value
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="HSNCode"
              name="HSNCode"
              label="HSNCode"
              fullWidth
              autoComplete="off"
              variant="outlined"
              inputProps={{ readOnly: hsncode ? true : false }}
              InputLabelProps={{
                shrink: inputField.HSNCode !== "", // This will shrink the label when there's a value
              }}
              value={inputField.HSNCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="IdntNo"
              name="IdntNo"
              label="Identification No"
              fullWidth
              autoComplete="off"
              variant="outlined"
              value={inputField.IdntNo}
              onChange={handleChange}
            />
          </Grid>
          {DocType === "MRR" ? (
            <>
              <Grid item xs={12} sm={2}>
                <TextField
                  required
                  id="Chln_Qty"
                  name="Chln_Qty"
                  label="Challan Qty"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  value={inputField.Chln_Qty}
                  type="number"
                  onChange={handleChhanChange}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  required
                  id="Recd_Qty"
                  name="Recd_Qty"
                  label="Recieved Qty"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  value={recqty}
                  type="number"
                  onChange={handleRecChange}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  required
                  id="Rejected_Qty"
                  name="Rejected_Qty"
                  label="Rejected Qty"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  value={rejqty}
                  type="number"
                  onChange={handleRejChange}
                />
              </Grid>
              <Grid item xs={12} sm={DocType === "MRR" ? 2 : 3}>
                <TextField
                  required
                  id="Accepted_Qty"
                  name="Accepted_Qty"
                  label="Accepted Qty"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  value={quantity}
                  type="number"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: quantity !== "", // This will shrink the label when there's a value
                  }}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={DocType === "MRR" ? 2 : 3}>
                <TextField
                  required
                  id="BalQty"
                  name="BalQty"
                  label="Balance Qty"
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  value={selectedItemid && selectedItemid.BalQty}
                  type="number"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: selectedItemid && selectedItemid.BalQty !== "", // This will shrink the label when there's a value
                  }}
                />
              </Grid>
              {DocType === "REJ" || DocType === "ISS" ? (
                <Grid item xs={12} sm={DocType === "MRR" ? 2 : 3}>
                  <TextField
                    required
                    id="Issue_Qty"
                    name="Issue_Qty"
                    label={DocType === "ISS" ? "Issue Qty" : "Reject Qty"}
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    value={issqty}
                    onChange={handleIssChange}
                    type="number"
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={DocType === "MRR" ? 2 : 3}>
                  <TextField
                    required
                    id="Accepted_Qty"
                    name="Accepted_Qty"
                    label="Recieved Qty"
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    value={quantity}
                    onChange={handleRetChange}
                    type="number"
                  />
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12} sm={DocType === "MRR" ? 2 : 3}>
            <TextField
              required
              id="Rate"
              name="Rate"
              label="Rate"
              fullWidth
              autoComplete="off"
              variant="outlined"
              value={rate}
              type="number"
              onChange={handleRateChange}
            />
          </Grid>
          <Grid item xs={12} sm={DocType === "MRR" ? 2 : 3}>
            <TextField
              required
              id="Value"
              name="Value"
              label="Value"
              fullWidth
              autoComplete="off"
              variant="outlined"
              value={total}
              type="number"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: inputField.total !== "", // This will shrink the label when there's a value
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="Narration"
              name="Narration"
              label={DocType === "MRR" ? "Narration" : "Location No"}
              fullWidth
              autoComplete="off"
              variant="outlined"
              value={inputField.Narration}
              InputLabelProps={{
                shrink: inputField.Narration !== "", // This will shrink the label when there's a value
              }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Remarks"
              name="Remarks"
              label="Remarks"
              fullWidth
              autoComplete="off"
              variant="outlined"
              value={inputField.Remarks}
              InputLabelProps={{
                shrink: inputField.Remarks !== "", // This will shrink the label when there's a value
              }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 0, mb: 2, mr: 1 }}
            >
              {mode ? "Update" : "Add"} {DocType} Item
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 0, mb: 2, mr: 1 }}
              type="button"
              onClick={() => handleReset()}
            >
              Reset
            </Button>
            <Button
              type="button"
              variant="contained"
              sx={{ mt: 0, mb: 2, mr: 1 }}
              onClick={handleButtonClick}
            >
              Back to {DocType}
            </Button>
            {mode ? (
              <Button
                type="button"
                variant="contained"
                sx={{ mt: 0, mb: 2, mr: 1 }}
                onClick={() => handleDelete()}
              >
                Delete
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Box>
      <DetailItemList
        DocType={DocType}
        data={Storedata}
        sendDataToParent={handleDataFromChild}
      />
    </Container>
  );
}
