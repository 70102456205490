import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StoreMRRList from "./mrrlist";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabsList() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();

  const myArray = ["MRR", "ISS", "REJ", "RET"];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (location.state) {
      if (location.state.DocType) {
        const index = myArray.indexOf(location.state.DocType);
        if (index !== -1) {
          setValue(index);
        } else {
          setValue(0);
        }
      }
      if (location.state.index) {
        setValue(location.state.index);
      }
    }
  }, []);
  return (
    <Container maxWidth="false" sx={{ mt: 1, mb: 1 }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="MRR" {...a11yProps(0)} />
            <Tab label="ISSUE" {...a11yProps(1)} />
            <Tab label="REJECT" {...a11yProps(2)} />
            <Tab label="RETURN" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <StoreMRRList DocType={"MRR"} DocRI={"R"} index={0}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <StoreMRRList DocType={"ISS"} DocRI={"I"} index={1}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <StoreMRRList DocType={"REJ"} DocRI={"R"} index={2}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <StoreMRRList DocType={"RET"} DocRI={"I"} index={3}/>
        </CustomTabPanel>
      </Box>
    </Container>
  );
}
