import * as React from "react";
import Typography from "@mui/material/Typography";
import reportService from "../services/report.service";
import AuthService from "../services/auth.service";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function StockReport() {
  const [Storedata, setStoredata] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedDate1, setSelectedDate1] = React.useState(dayjs);
  const [type, setType] = React.useState();

  const strnm = AuthService.getStoreName();

  const [reportType, setReportType] = React.useState("");

  const handleChange = (event) => {
    setReportType(event.target.value);
  };

  const getItemStockData = () => {
    if (reportType === "") {
      alert("Please select report type.");
      return false;
    }
    setLoading(true);
    setStoredata({});
    const Str_id = AuthService.getStoreId();
    reportService
      .getItemStock(Str_id, reportType, selectedDate, selectedDate1)
      .then(
        (response) => {
          if (response.data.status) {
            setType(reportType);
            setStoredata(response.data.data);
          }
          setLoading(false);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          console.log(_content);
          setLoading(false);
        }
      );
  };

  const columns1 = [
    {
      field: "Store",
      headerName: "Store",
      width: 150,
    },
    {
      field: "Item Code",
      headerName: "Item Code",
      width: 125,
    },
    {
      field: "Type",
      headerName: "Type",
      width: 150,
    },
    {
      field: "HSN Code",
      headerName: "HSN Code",
      width: 125,
    },
    {
      field: "Item Desc",
      headerName: "Item Desc",
      sortable: false,
      width: 400,
    },
    {
      field: "UOM",
      headerName: "UOM",
      sortable: false,
      width: 100,
    },
    {
      field: "Op Qty",
      headerName: "Op Qty",
      sortable: false,
      width: 100,
    },
    {
      field: "Rec Qty",
      headerName: "Rec Qty",
      sortable: false,
      width: 100,
    },
    {
      field: "Iss Qty",
      headerName: "Iss Qty",
      sortable: false,
      width: 100,
    },
    {
      field: "Bal Qty",
      headerName: "Bal Qty",
      sortable: false,
      width: 100,
    },
  ];

  const columns2 = [
    {
      field: "Store",
      headerName: "Store",
      width: 150,
    },
    {
      field: "Doc No",
      headerName: "Doc No",
      width: 100,
    },
    {
      field: "Doc Type",
      headerName: "Doc Type",
      width: 100,
    },
    {
      field: "Doc Date",
      headerName: "Doc Date",
      width: 100,
    },
    {
      field: "Vendor",
      headerName: "Vendor",
      sortable: false,
      width: 200,
    },
    {
      field: "PO No",
      headerName: "PO No",
      sortable: false,
      width: 100,
    },
    {
      field: "PO Date",
      headerName: "PO Date",
      sortable: false,
      width: 100,
    },
    {
      field: "Chln No",
      headerName: "Chln No",
      sortable: false,
      width: 100,
    },
    {
      field: "Chln Date",
      headerName: "Chln Date",
      sortable: false,
      width: 100,
    },
    {
      field: "LR No & Date",
      headerName: "LR No & Date ",
      sortable: false,
      width: 180,
    },
    {
      field: "Contractor",
      headerName: "Contractor ",
      sortable: false,
      width: 250,
    },
    {
      field: "Ref Store",
      headerName: "Ref Store",
      sortable: false,
      width: 100,
    },
    {
      field: "Item Code",
      headerName: "Item Code",
      sortable: false,
      width: 100,
    },
    {
      field: "Item Desc",
      headerName: "Item Desc",
      sortable: false,
      width: 250,
    },
    {
      field: "UOM",
      headerName: "UOM",
      sortable: false,
      width: 100,
    },
    {
      field: "HSN Code",
      headerName: "HSN Code",
      sortable: false,
      width: 100,
    },
    {
      field: "Chln Qty",
      headerName: "Chln Qty",
      sortable: false,
      width: 100,
    },
    {
      field: "Recd Qty",
      headerName: "Recd Qty",
      sortable: false,
      width: 100,
    },
    {
      field: "Rej Qty",
      headerName: "Rej Qty",
      sortable: false,
      width: 100,
    },
    {
      field: "Accpted Qty",
      headerName: "Accpted Qty",
      sortable: false,
      width: 100,
    },
    {
      field: "Issue Qty",
      headerName: "Issue Qty",
      sortable: false,
      width: 100,
    },
    {
      field: "Rate",
      headerName: "Rate",
      sortable: false,
      width: 150,
    },
    {
      field: "Value",
      headerName: "Value",
      sortable: false,
      width: 150,
    },
    {
      field: "Indent Ref",
      headerName: "Indent Ref",
      sortable: false,
      width: 100,
    },
    {
      field: "Indent Req By",
      headerName: "Indent Req By",
      sortable: false,
      width: 100,
    },
    {
      field: "Line Name",
      headerName: "Line Name",
      sortable: false,
      width: 250,
    },
    {
      field: "Road Permitt",
      headerName: "Road Permitt",
      sortable: false,
      width: 100,
    },
    {
      field: "MODE",
      headerName: "MODE",
      sortable: false,
      width: 100,
    },
    {
      field: "Transporter",
      headerName: "Transporter",
      sortable: false,
      width: 100,
    },
    {
      field: "Location",
      headerName: "Location",
      sortable: false,
      width: 100,
    },
    {
      field: "eWay Bill No",
      headerName: "eWay Bill No",
      sortable: false,
      width: 100,
    },
    {
      field: "eWay Bill Date",
      headerName: "eWay Bill Date",
      sortable: false,
      width: 100,
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      sortable: false,
      width: 100,
    },
  ];

  return (
    <Container maxWidth="false" sx={{ mt: 1, mb: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={3} container justify="flex-end" alignItems="center">
          <Typography
            id="transition-modal-title"
            variant="body1"
            sx={{ textAlign: "left" }}
          >
            Store Name : {strnm} - Stock Report
          </Typography>
        </Grid>
        <Grid item xs={9} textAlign={"right"}>
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <InputLabel id="demo-select-small-label">Select Report</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={reportType}
              label="Select Report"
              onChange={handleChange}
            >
              <MenuItem value={"RR"}>Receipt Register (MRR & Iss Ret)</MenuItem>
              <MenuItem value={"IR"}>Issue Register (Cont Iss & Rej)</MenuItem>
              <MenuItem value={"CR"}>
                Contractor Register (Iss & Iss Ret)
              </MenuItem>
              <MenuItem value={"SS"}>Store to Store Transfer</MenuItem>
              <MenuItem value={"STK"}>Item wise Stock Status</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100% " }}
                required
                slotProps={{
                  actionBar: { actions: ["clear"] },
                  textField: { size: "small" },
                }}
                id="DocDate"
                name="DocDate"
                label={"From Date"}
                format="DD/MM/YYYY"
                maxDate={dayjs()}
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "100% " }}
                required
                slotProps={{ textField: { size: "small" } }}
                id="DocDate"
                name="DocDate"
                label={"To Date"}
                format="DD/MM/YYYY"
                maxDate={dayjs()}
                value={selectedDate1}
                onChange={(newValue) => {
                  setSelectedDate1(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <Button
              type="submit"
              variant="contained"
              sx={{ mb: 2 }}
              onClick={getItemStockData}
            >
              Submit
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DataGrid
        sx={{
          height: 500,
          width: "100%",
          boxShadow: 2,
          border: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        //getRowHeight={getRowHeight}
        getRowId={(Storedata) => Storedata.id}
        rows={Storedata}
        columns={type !== "STK" ? columns2 : columns1}
        pageSize={100}
        rowHeight={25}
        density="standard"
        rowsPerPageOptions={[100]}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </Container>
  );
}
