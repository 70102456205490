import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Copyright from "./footer";
import UserService from "../services/user.service";
import { useNavigate } from "react-router-dom";

function ChangePass(props) {
  //let navigate = useNavigate();

  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confpassword, setConfPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("success");
  const navigate = useNavigate();
  const onChangeOldPassword = (e) => {
    const username = e.target.value;
    setOldPassword(username);
  };

  const onChangeNewPassword = (e) => {
    const password = e.target.value;
    setNewPassword(password);
  };

  const onChangeConfPassword = (e) => {
    const password = e.target.value;
    setConfPassword(password);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setMessage("");
    setLoading(true);
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    UserService.ChangePassword(
      data.get("oldpassword"),
      data.get("newpassword"),
      data.get("confpassword")
    ).then(
      (response) => {
        setLoading(false);
        const resMessage =
          (response && response.data && response.data.message) ||
          response.data.message ||
          response.data.message.toString();
        setMessage(resMessage);
        const resStatus =
          (response && response.data && response.data.status) ||
          response.data.status ||
          response.data.status.toString();
        resStatus === 400 ? setStatus("error") : setStatus("success");
        if (resStatus === 200) {
          setOldPassword("");
          setNewPassword("");
          setConfPassword("");
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const handleButtonClick = (params) => {
    navigate("/storemrrlist");
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        {message ? <Alert severity={status}>{message}</Alert> : ""}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="oldpassword"
            label="Old Password"
            type="password"
            id="oldpassword"
            autoComplete="current-password"
            autoFocus
            value={oldpassword}
            onChange={onChangeOldPassword}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="newpassword"
            label="New Password"
            type="password"
            id="newpassword"
            autoComplete="current-password"
            value={newpassword}
            onChange={onChangeNewPassword}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confpassword"
            label="Confirm Password"
            type="password"
            id="confpassword"
            autoComplete="current-password"
            value={confpassword}
            onChange={onChangeConfPassword}
          />
          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
            Update Password
          </Button>{" "}
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 3, mb: 2, ml: 5 }}
            onClick={handleButtonClick}
          >
            Back to MRR
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

export default ChangePass;
