import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  LinearProgress,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import uploadObject from "../services/upload.service";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Link } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
function UploadButton({ Str_id, id, callparentfun }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleOpenDialog = () => {
    setMessage(null);
    setIsError(false);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    callparentfun();
    setCurrentFile(null);
    setSelectedFiles(null);
    setOpenDialog(false);
  };

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files[0]);
  };

  const handleUpload = () => {
    let currentFile = selectedFiles;
    setProgress(0);
    setCurrentFile(currentFile);
    uploadObject
      .upload(currentFile, Str_id, id, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
      .then((response) => {
        setMessage(response.data.message);
        setIsError(true);
        if (response.data.success) {
          setIsError(false);
        }
      })
      .catch(() => {
        setProgress(0);
        //setMessage("Could not upload the file!");
        setCurrentFile(null);
        setIsError(false);
      });
    setSelectedFiles(null);
  };
  return (
    <Box>
      <IconButton
        color="secondary"
        aria-label="Sent Approval"
        onClick={handleOpenDialog}
      >
        <UploadFileIcon></UploadFileIcon>
      </IconButton>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
        {currentFile && (
          <Box className="mb25" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
              <Typography
                variant="body2"
                color="textSecondary"
              >{`${progress}%`}</Typography>
            </Box>
          </Box>
        )}
        <DialogTitle>
          {" "}
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={6}>
              Upload Files
            </Grid>
            <Grid item xs={12} sm={6} textAlign={"right"}>
              <Link color="primary" target="_blank" to={`/viewfile/${id}`}>
                <PreviewIcon></PreviewIcon>
              </Link>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div className="mg20">
            <label htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: "none" }}
                type="file"
                onChange={handleFileChange}
              />
              <Button
                className="btn-choose"
                variant="outlined"
                component="span"
              >
                Choose Files
              </Button>
            </label>
            <div className="file-name">
              {selectedFiles && selectedFiles.length > 0
                ? selectedFiles[0].name
                : null}
            </div>
            <Button
              className="btn-upload"
              color="primary"
              variant="contained"
              component="span"
              disabled={!selectedFiles}
              onClick={handleUpload}
            >
              Upload
            </Button>
            <Typography
              variant="subtitle2"
              className={`upload-message ${isError ? "error" : "success"}`}
            >
              {message}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default UploadButton;
