import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import logo from "../assets/logo.png";
import AuthService from "../services/auth.service";

//const pages = ["Store", "Reports", "Tools"];
//const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  const handleCloseNavMenuStock = () => {
    setAnchorElNav(null);
    window.location.href = "/storemrrlist";
  };

  
  const handleCloseNavMenuReport = () => {
    setAnchorElNav(null);
    window.location.href = "/report";
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseUserMenuChangePass = () => {
    setAnchorElUser(null);
    window.location.href = "/changepass";
  };

  const handleCloseUserMenuLogout = () => {
    setAnchorElUser(null);
    AuthService.logout();
    //navigate("/");
    window.location.href = "/";
  };

  return (
    <AppBar position="static" style={{ background: "#FFF" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Box
              component="img"
              sx={{ marginRight: 1 }}
              alt="Logo"
              src={logo}
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {/* {pages.map((page) => ( */}
                <MenuItem key="Stock" onClick={handleCloseNavMenuStock}>
                  <Typography textAlign="center" color="primary.main">
                    Stock
                  </Typography>
                </MenuItem>
                <MenuItem key="Reports" onClick={handleCloseNavMenuReport}>
                <Typography textAlign="center" color="primary.main">
                  Reports
                </Typography>
              </MenuItem>
              <MenuItem key="Tools" onClick={handleCloseNavMenu}>
              <Typography textAlign="center" color="primary.main">
                Tools
              </Typography>
            </MenuItem>
              {/* ))} */}
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "in herit",
              textDecoration: "none",
            }}
          >
            <Box
              component="img"
              sx={{ marginRight: 1 }}
              alt="Logo"
              src={logo}
            />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {/* {pages.map((page) => ( */}
            <Button
              key="Stock"
              onClick={handleCloseNavMenuStock}
              sx={{ my: 2, color: "primary.main", display: "block" }}
            >
              Stock
            </Button>
            <Button
              key="Reports"
              onClick={handleCloseNavMenuReport}
              sx={{ my: 2, color: "primary.main", display: "block" }}
            >
              Reports
            </Button>
            <Button
              key="Tools"
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "primary.main", display: "block" }}
            >
              Tools
            </Button>
            {/* ))} */}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}
              <MenuItem key={"Profile"} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem key={"Account"} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Account</Typography>
              </MenuItem>
              <MenuItem
                key={"ChangePassword"}
                onClick={handleCloseUserMenuChangePass}
              >
                <Typography textAlign="center">Change Password</Typography>
              </MenuItem>
              <MenuItem key={"Logout"} onClick={handleCloseUserMenuLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
