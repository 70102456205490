import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useEffect } from "react";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token) {
      const decodedJwt = parseJwt(user.access_token);
      if (decodedJwt.exp && decodedJwt.exp * 1000 < Date.now()) {
        //props.logOut();
        let token = AuthService.refreshToken();
        user.access_token = token.access_token;
        localStorage.setItem("user", JSON.stringify(token));
      } 
    } else {
      AuthService.logout();
      navigate("/login");
    }
  }, []);

  return ;
};

export default AuthVerify;