import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_BASE_URL;
const OTHER_API = process.env.REACT_APP_PREVIEW_BASE_URL;

const getItemStock = (Str_Id, type, fdate, tdate) => {
  return axios.post(
    API_URL + "getitemstock",
    { Str_Id, type, fdate, tdate },
    { headers: authHeader() }
  );
};

const getPreivewURL = () => {
  return OTHER_API;
}

const reportObject = {
  getItemStock,
  getPreivewURL
};
export default reportObject;
