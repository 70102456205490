import axios from "axios";
import authHeader from "./auth-header";
//axios.defaults.withCredentials = true;
const API_URL = process.env.REACT_APP_API_BASE_URL;

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = async (email, password) => {
  const response = await axios.post(API_URL + "login", {
    email,
    password,
  });
  if (response.status === 200 && response.data.access_token) {
    localStorage.setItem("user", JSON.stringify(response.data));
    localStorage.setItem("isLoggedIn", 1);
  }
  return response.data;
};

const logout = () => {
  localStorage.clear();
  localStorage.removeItem("user");
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("Str_Id");
  localStorage.removeItem("Strname");
  localStorage.removeItem("LineName");
};

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.user) {
    return user.user.userid;
  }
};

const getStoreId = () => {
  const Str_Id = JSON.parse(localStorage.getItem("Str_Id"));
  if (Str_Id) {
    return Str_Id;
  }
};

const getStoreName = () => {
  const Strname = JSON.parse(localStorage.getItem("Strname"));
  if (Strname) {
    return Strname;
  }
};

const getStoreLineName = () => {
  const LineName = JSON.parse(localStorage.getItem("LineName"));
  if (LineName) {
    return LineName;
  }
};

const getLoggedinUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.user) {
    return user.user;
  }
};

const refreshToken = async () => {
  const response = await axios.post(
    API_URL + "refresh",
    {},
    {
      headers: authHeader(),
    }
  );
  if (response.status === 200 && response.data.access_token) {
    return response.data;
  }
  return null;
};

const getToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.access_token) {
    return user.access_token;
  }
};

const authObject = {
  register,
  login,
  logout,
  getCurrentUser,
  getLoggedinUser,
  getStoreId,
  getStoreName,
  getStoreLineName,
  refreshToken,
  getToken,
};
export default authObject;
