import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

export default function DetailItemList({ DocType, data, sendDataToParent }) {
  const handleRowDoubleClick = (params) => {
    //localStorage.setItem("Str_Id", params.row.Str_Id);
    // Navigate to the DetailsPage with the ID of the clicked row
    //navigate("/detailsitem/" + params.row.id);
    sendDataToParent(params.row);
    //window.location.href = "/storeoperation";
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "ItemDesc",
      headerName: "Item Name",
      width: 300,
    },
    {
      field: "UOM",
      headerName: "UOM",
      width: 80,
    },
    {
      field: "IdntNo",
      headerName: "Identification No",
      width: 160,
    },
    {
      field: "Chln_Qty",
      headerName: "Challan Qty",
      width: 160,
    },
    {
      field: "Recd_Qty",
      headerName: "Received Qty",
      width: 160,
    },
    {
      field: "Rejected_Qty",
      headerName: "Rejected Qty",
      width: 160,
    },
    {
      field: "Accepted_Qty",
      headerName: "Accepted Qty",
      sortable: false,
      width: 160,
    },
    {
      field: "Rate",
      headerName: "Rate",
      sortable: false,
      width: 160,
    },
    {
      field: "Value",
      headerName: "Total",
      sortable: false,
      width: 160,
    },
  ];

  const columns1 = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "ItemDesc",
      headerName: "Item Name",
      width: 300,
    },
    {
      field: "UOM",
      headerName: "UOM",
      width: 80,
    },
    {
      field: "IdntNo",
      headerName: "Identification No",
      width: 160,
    },
    {
      field: "Issue_Qty",
      headerName: "Issue Qty",
      sortable: false,
      width: 160,
    },
    {
      field: "Rate",
      headerName: "Rate",
      sortable: false,
      width: 160,
    },
    {
      field: "Value",
      headerName: "Total",
      sortable: false,
      width: 160,
    },
    {
      field: "Narration",
      headerName: "Narration",
      sortable: false,
      width: 160,
    },
    {
      field: "Remarks",
      headerName: "Remarks",
      sortable: false,
      width: 160,
    }
  ];
  return (
    <Box>
      {/* <Typography id="transition-modal-title" variant="h6" component="h2">
            MRR Item List
            </Typography> */}
      <DataGrid
        sx={{
          height: 500,
          width: "100%",
          boxShadow: 2,
          border: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        getRowId={(data) => data.id}
        rows={data}
        columns={DocType === "MRR" ? columns : columns1}
        pageSize={100}
        rowHeight={25}
        density="standard"
        rowsPerPageOptions={[100]}
        onRowDoubleClick={handleRowDoubleClick}
      />
    </Box>
  );
}
