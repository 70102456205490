import "./App.css";
import * as React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
//import Badge from "@mui/material/Badge";
import AuthService from "../src/services/auth.service";
import SignIn from "./components/login";
import ResponsiveAppBar from "./components/menubar";
import BasicTabs from "./components/tabs";
import BasicTabsList from "./components/tabslist";
import DetialsItem from "./components/detailsitem";
import Password from "./components/password";
import PdfViewer from "./components/viewfile";
import AuthVerify from "./services/AuthVerify";
import ChangePass from "./components/changepass";
import StockReport from "./components/report";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F7941D",
    },
    secondary: {
      main: "#ED1C24",
    },
  },
});
const UserId = AuthService.getCurrentUser();
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        {UserId ? <ResponsiveAppBar /> : null}
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/home" element={<ResponsiveAppBar />} />
          <Route path="/forgotpassword" element={<Password />} />
          <Route path="/storeoperation" element={<BasicTabs />} />
          <Route path="/detailsitem" element={<DetialsItem />} />
          <Route path="/storemrrlist" element={<BasicTabsList />} />
          <Route path="/changepass" element={<ChangePass />} />
          <Route path="/report" element={<StockReport />} />
          <Route path="/viewfile">
            <Route index element={<PdfViewer />} />
            <Route path=":id" element={<PdfViewer />}></Route>
          </Route>
        </Routes>
        <AuthVerify/>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
