import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MRR from "./mrr";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const DocType = location.state.DocType;
  const myArray = ["MRR", "ISS", "REJ", "RET"];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    if (location.state) {
      if (location.state.DocType) {
        const index = myArray.indexOf(location.state.DocType);
        if (index !== -1) {
          setValue(index);
        } else {
          setValue(0);
        }
      }
      if (location.state.index) {
        setValue(location.state.index);
      }
    }
  }, []);

  return (
    <Container maxWidth="false" sx={{ mt: 1, mb: 1 }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="MRR"
              {...a11yProps(0)}
              disabled={DocType === "MRR" ? false : true}
            />
            <Tab
              label="ISSUE"
              {...a11yProps(1)}
              disabled={DocType === "ISSUE" ? false : true}
            />
            <Tab
              label="REJECT"
              {...a11yProps(2)}
              disabled={DocType === "REJECT" ? false : true}
            />
            <Tab
              label="RETURN"
              {...a11yProps(3)}
              disabled={DocType === "RETURN" ? false : true}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <MRR DocType={"MRR"} DocRI={"R"} index={0} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <MRR DocType={"ISS"} DocRI={"I"} index={1} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <MRR DocType={"REJ"} DocRI={"R"} index={2} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <MRR DocType={"RET"} DocRI={"I"} index={3} />
        </CustomTabPanel>
      </Box>
    </Container>
  );
}
