import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import MrrService from "../services/mrr.service";
import AuthService from "../services/auth.service";
import { DataGrid } from "@mui/x-data-grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function StoreModel({ isOpen, onClose }) {
  const [Storedata, setStoredata] = React.useState({});

  React.useEffect(() => {
    const UserId = AuthService.getCurrentUser();
    MrrService.getStoreList(UserId).then(
      (response) => {
        if (response.data.status) {
          setStoredata(response.data.data);
        }
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(_content);
      }
    );
  }, []);

  const getRowHeight = (params) => {
    // You can calculate the row height based on your data here.
    // For example, you can make every even row taller.
    return params.rowIndex % 2 === 0 ? 40 : 20;
  };

  const handleRowDoubleClick = (params) => {
    localStorage.setItem("Str_Id", params.row.Str_Id);
    localStorage.setItem("Strname", JSON.stringify(params.row.Store));
    localStorage.setItem("LineName", JSON.stringify(params.row.LineName));
    // Navigate to the DetailsPage with the ID of the clicked row
    //navigate("/storeoperation");
    window.location.href = "/storemrrlist";
  };

  const columns = [
    {
      field: "Store",
      headerName: "Store",
      width: 200,
    },
    {
      field: "ProjectType",
      headerName: "Type",
      width: 150,
    },
    {
      field: "ProjectCode",
      headerName: "Code",
      width: 200,
    },
    {
      field: "ProjectDesc",
      headerName: "Description",
      width: 200,
    },
    {
      field: "LineName",
      headerName: "Line Name",
      width: 350,
    },
    {
      field: "Location",
      headerName: "Location",
      sortable: false,
      width: 160,
    },
  ];
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 2000,
          },
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Project Store List
            </Typography>
            <DataGrid
              sx={{
                height: 500,
                width: "100%",
                boxShadow: 2,
                border: 2,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              getRowHeight={getRowHeight}
              getRowId={(Storedata) => Storedata.Str_Id}
              rows={Storedata}
              columns={columns}
              pageSize={100}
              rowHeight={25}
              density="standard"
              rowsPerPageOptions={[100]}
              onRowDoubleClick={handleRowDoubleClick}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
