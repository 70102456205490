import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MrrService from "../services/mrr.service";
import AuthService from "../services/auth.service";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export default function MRR(props) {
  const initialFormData = {
    DocDate: "",
    LRNo: "",
    VehNo: "",
    RoadPermitTo: "",
    Chln_Inv_No: "",
    Chln_Inv_Date: "",
    Remarks: "",
    Mode: "",
    NoOfPacks: 0,
    IndentRef: "",
    Po_No: "",
    Po_Date: "",
    Iss_DeleveryAddress: "",
    Iss_DeleveryPIN: "",
    Transporter: "",
    Indent_ReqBy: "",
    PLId: "",
    id: 0,
  };

  const [inputField, setInputField] = React.useState({});
  const [VCData, setVCData] = React.useState({});
  const [storeData, setStoreData] = React.useState({});
  const [selectedVCid, setSelectedVCid] = React.useState(null);
  const [selectedStrRefId, setSelectedStrRefId] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [selectedDate1, setSelectedDate1] = React.useState(dayjs());
  const [selectedDate2, setSelectedDate2] = React.useState(dayjs());
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [mode, setMode] = React.useState(0);
  const [lastDocNo, setLastDocNo] = React.useState(0);
  const [isProject, setIsProject] = React.useState(0);
  const [lines, setLines] = React.useState({});
  const [selectedLineid, SetSelectedLineid] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const strnm = AuthService.getStoreName();

  const isRET = props && props.DocType === "RET";
  const isISS = props && props.DocType === "ISS";
  const isMRR = props && props.DocType === "MRR";
  const isREJ = props && props.DocType === "REJ";

  const UserId = AuthService.getCurrentUser();
  const Str_id = AuthService.getStoreId();
  const handleChange = (evt) => {
    const value = evt.target.value;
    setInputField({
      ...inputField,
      [evt.target.name]: value,
    });
  };

  const handleInputChange = (event, newValue) => {
    setSelectedVCid(newValue);
  };
  const handleLineChange = (event, newValue) => {
    SetSelectedLineid(newValue);
  };

  const handleInputSTRChange = (event, newValue) => {
    setSelectedStrRefId(newValue);
  };

  const getStoreData = () => {
    MrrService.getStoreRefData(Str_id, UserId).then(
      (response) => {
        if (response.data.status) {
          setStoreData(response.data.data);
          if (location.state && location.state.Str_Id_Ref) {
            const found = response.data.data.find((obj) => {
              return obj.id === location.state.Str_Id_Ref;
            });
            setSelectedStrRefId(found);
          }
          setLoading(false);
        }
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(_content);
      }
    );
  };

  const getVCData = () => {
    MrrService.getVCdetails(UserId, props.DocType).then(
      (response) => {
        if (response.data.status) {
          setVCData(response.data.data);
          if (location.state && location.state.VendId) {
            const found = response.data.data.find((obj) => {
              return obj.id === location.state.VendId;
            });
            setSelectedVCid(found);
          }
          if (location.state && location.state.ContId) {
            const found = response.data.data.find((obj) => {
              return obj.id === location.state.ContId;
            });
            setSelectedVCid(found);
          }
          setLoading(false);
        }
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(_content);
      }
    );
  };

  const getLines = () => {
    MrrService.getLines(Str_id).then(
      (response) => {
        if (response.data.status) {
          setLines(response.data.data);
          if (location.state && location.state.PLId) {
            const found = response.data.data.find((obj) => {
              return obj.id === location.state.PLId;
            });
            SetSelectedLineid(found);
          }
        }
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(_content);
      }
    );
  };

  React.useEffect(() => {
    setLoading(true);
    if (location.state && location.state.id) {
      setMode(location.state.id);
      setInputField(location.state);
      setLastDocNo(location.state.DocNo);
      setSelectedDate(dayjs(new Date(location.state.DocDate)));
      setSelectedDate1(dayjs(new Date(location.state.Chln_Inv_Date)));
      setSelectedDate2(dayjs(new Date(location.state.Po_Date)));
    } else {
      MrrService.getLatestSrNo(Str_id, props.DocType).then(
        (response) => {
          if (response.data.status) {
            setInputField({
              ...inputField,
              ["DocNo"]: response.data.data,
            });
            setLastDocNo(response.data.data);
          }
          setLoading(false);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          console.log(_content);
          setLoading(false);
        }
      );
    }
    getLines();
    if (location.state && location.state.Str_Id_Ref > 0) {
      setIsProject("1");
      getStoreData();
    } else {
      getVCData();
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setSeverity("");
    const data = new FormData(event.currentTarget);
    data.append("DocRI", props.DocRI); //append the values with key, value pair
    data.append("DocType", props.DocType);
    data.append("VendId", selectedVCid && selectedVCid.id);
    data.append("ContId", selectedVCid && selectedVCid.id);
    data.append("Str_Id_Ref", selectedStrRefId && selectedStrRefId.id);
    data.append("PLId", selectedLineid && selectedLineid.id);
    data.append("UserId", UserId);
    data.append("Str_id", Str_id);
    data.append("DocDate", selectedDate);
    data.append("Chln_Inv_Date", selectedDate1);
    data.append("Po_Date", selectedDate2);
    data.append("id", mode);
    MrrService.createMrr(data).then(
      (response) => {
        setOpen(true);
        setMessage(response.data.msg);
        setSeverity(response.data.status);
        if (response.data.status === "success") {
          setInputField(initialFormData);
          setSelectedVCid(null);
          setSelectedDate(null);
          setSelectedDate1(null);
          setSelectedDate2(null);
          setTimeout(
            () =>
              navigate("/detailsitem", {
                state: {
                  id: response.data.data.id,
                  DocType: response.data.data.DocType,
                  DocNo: response.data.data.DocNo,
                  DocDate: response.data.data.DocDate,
                },
              }),
            1000
          );
        }
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        console.log(resMessage);
      }
    );
  };

  const handleButtonClick = (params) => {
    //localStorage.setItem("Str_Id", params.row.Str_Id);
    // Navigate to the DetailsPage with the ID of the clicked row
    navigate("/storemrrlist", {
      replace: true,
      state: { DocType: props.DocType },
    });
    //window.location.href = "/storeoperation";
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleRadio = (event) => {
    setLoading(true);
    setIsProject(event.target.value);
    if (event.target.value === "1") {
      getStoreData();
    } else {
      getVCData();
    }
    setLoading(false);
  };
  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{ mt: 1 }}
    >
      <Grid container spacing={1}>
        {isMRR || isISS ? (
          <Grid item xs={6}>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="isProject"
              value={isProject}
              onChange={handleRadio}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={isMRR ? "Receive from supplier" : "Issue to contractor"}
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={
                  isMRR
                    ? "Receive from other project"
                    : "Issue to other project"
                }
              />
            </RadioGroup>
          </Grid>
        ) : null}

        <Grid item xs={isMRR || isISS ? 6 : 12}>
          <Typography
            id="transition-modal-title"
            variant="body1"
            sx={{ textAlign: "right" }}
          >
            Store Name : {strnm}
          </Typography>
        </Grid>
      </Grid>
      <Divider></Divider>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3} marginTop={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="DocNo"
            name="DocNo"
            label="Document No"
            fullWidth
            autoComplete="off"
            variant="outlined"
            inputProps={{ readOnly: lastDocNo ? true : false }}
            InputLabelProps={{
              shrink: inputField.DocNo !== "", // This will shrink the label when there's a value
            }}
            value={inputField.DocNo}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100% " }}
              required
              id="DocDate"
              name="DocDate"
              label={props.DocType + " Date"}
              format="DD/MM/YYYY"
              value={selectedDate}
              maxDate={dayjs()}
              onChange={(newValue) => {
                setSelectedDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          {isMRR || isISS || isREJ || isRET ? (
            isProject === "1" ? (
              <>
                <Autocomplete
                  id="Str_Id_Ref"
                  name="Str_Id_Ref"
                  disableClearable
                  options={storeData} // Provide the fetched data as options
                  getOptionLabel={(option) => (option ? option.name : "")} // Define how to display the option label
                  value={selectedStrRefId}
                  onChange={handleInputSTRChange}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store Name"
                      variant="outlined"
                    />
                  )}
                />
              </>
            ) : isMRR || isREJ ? (
              <>
                <Autocomplete
                  id="VendId"
                  name="VendId"
                  disableClearable
                  options={VCData} // Provide the fetched data as options
                  getOptionLabel={(option) => option.name} // Define how to display the option label
                  value={selectedVCid}
                  onChange={handleInputChange}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vendor Name"
                      variant="outlined"
                    />
                  )}
                />
              </>
            ) : (
              <>
                <Autocomplete
                  id="ContId"
                  name="ContId"
                  disableClearable
                  options={VCData} // Provide the fetched data as options
                  getOptionLabel={(option) => option.name} // Define how to display the option label
                  value={selectedVCid}
                  onChange={handleInputChange}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Contractor Name"
                      variant="outlined"
                    />
                  )}
                />
              </>
            )
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="LRNo"
            name="LRNo"
            label="LRNo & Date"
            fullWidth
            autoComplete="off"
            variant="outlined"
            value={inputField.LRNo}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="VehNo"
            name="VehNo"
            label="Vehicle No"
            fullWidth
            autoComplete="off"
            variant="outlined"
            value={inputField.VehNo}
            onChange={handleChange}
          />
        </Grid>
        {isMRR || isREJ ? (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="RoadPermitTo"
                name="RoadPermitTo"
                label="Road Permit To"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.RoadPermitTo}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="Chln_Inv_No"
                name="Chln_Inv_No"
                label="Challan/Invoice No"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.Chln_Inv_No}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100% " }}
                  required
                  id="Chln_Inv_Date"
                  name="Chln_Inv_Date"
                  label="Challan/Invoice Date"
                  format="DD/MM/YYYY"
                  value={selectedDate1}
                  maxDate={dayjs()}
                  onChange={(newValue) => {
                    setSelectedDate1(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                required
                id="Po_No"
                name="Po_No"
                label="PO NO"
                type="number"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.Po_No}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100% " }}
                  required
                  id="Po_Date"
                  name="Po_Date"
                  label="PO Date"
                  format="DD/MM/YYYY"
                  value={selectedDate2}
                  maxDate={dayjs()}
                  onChange={(newValue) => {
                    setSelectedDate2(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </>
        ) : null}
        {isRET || isISS ? (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="Mode"
                name="Mode"
                label="Mode"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.Mode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type="number"
                id="NoOfPacks"
                name="NoOfPacks"
                label="No. of Pack"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.NoOfPacks}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="IndentRef"
                name="IndentRef"
                label="Indent No & Date"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.IndentRef}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="Transporter"
                name="Transporter"
                label="Transporter & GSTIN"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.Transporter}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="Indent_ReqBy"
                name="Indent_ReqBy"
                label="Request By"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.Indent_ReqBy}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Iss_DeleveryAddress"
                name="Iss_DeleveryAddress"
                label="Delevery Address & PIN"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.Iss_DeleveryAddress}
                onChange={handleChange}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="Iss_DeleveryPIN"
                name="Iss_DeleveryPIN"
                label="Delevery GSTIN"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.Iss_DeleveryPIN}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="location_no"
                name="location_no"
                label="Location No"
                fullWidth
                autoComplete="off"
                variant="outlined"
                value={inputField.location_no}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="PLId"
                name="PLId"
                required
                disableClearable
                options={lines} // Provide the fetched data as options
                getOptionLabel={(option) => option.name} // Define how to display the option label
                value={selectedLineid}
                onChange={handleLineChange}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Site/Line Name"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} sm={6}>
          <TextField
            id="Remarks"
            name="Remarks"
            label="Remarks"
            fullWidth
            autoComplete="off"
            variant="outlined"
            value={inputField.Remarks}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2, mr: 1 }}
          >
            {mode === 0 ? "Create" : "Update"} {props.DocType}
          </Button>
          <Button
            type="button"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleButtonClick}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
