import React from "react";
import { useLocation, useParams } from "react-router-dom";
import reportObject from "../services/report.service";

const PdfViewer = () => {
  const params = useParams();
  const location = useLocation();
  // Parse the search string to get the query parameters
  const searchParams = new URLSearchParams(location.search);
  // Retrieve values using get method
  const token = searchParams.get("token");

  const doctype = searchParams.get("doctype");
  const id = params.id;

  const URL = reportObject.getPreivewURL();
  const thirdPartyURL =
    URL + `${id}/?token=` + token + `&doctype=${doctype}`;
  /* useEffect(() => {
    const fetchPdf = async () => {
      try {

        
        const response = await axios.get(API_URL + `download/${id}`, {
          responseType: 'arraybuffer',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        setPdfUrl(url);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
  }, [id]); */

  return (
    <div>
      <h5>Document Preview</h5>
      <iframe
        src={thirdPartyURL}
        title="Third Party Content"
        width="100%"
        height="600px"
        //sandbox="allow-same-origin allow-scripts"
      />
    </div>
  );
};

export default PdfViewer;
