import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const createItem = (data) => {
  return axios.post(API_URL + "storedocdetail", data, {
    headers: authHeader(),
  });
};

const getMasterItem = (Str_Id, DocType, Doc_Date) => {
  if (DocType !== "MRR") {
    return axios.post(
      API_URL + "iss_masteritemlist",
      { Str_Id, Doc_Date },
      { headers: authHeader() }
    );
  } else {
    return axios.post(
      API_URL + "masteritemlist",
      { Str_Id },
      { headers: authHeader() }
    );
  }
};

const getStoreItemList = (Doc_id) => {
  return axios.post(
    API_URL + "storeitemlist",
    { Doc_id },
    { headers: authHeader() }
  );
};

const deleteItem = (Doc_id, ItemId) => {
  return axios.post(
    API_URL + "deleteItem",
    { Doc_id, ItemId },
    { headers: authHeader() }
  );
};

const DocDetailObj = {
  getMasterItem,
  createItem,
  getStoreItemList,
  deleteItem,
};
export default DocDetailObj;
