import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const createMrr = (data) => {
  return axios.post(API_URL + "storeheader", data, { headers: authHeader() });
};

const sentApproval = (id, Str_Id, DocType, data) => {
  return axios.put(
    API_URL + "sentapproval",
    { id, Str_Id, DocType, data },
    { headers: authHeader() }
  );
};

const getLatestSrNo = (Str_Id, DocType, DocDate) => {
  return axios.post(
    API_URL + "getlatestdocno",
    { Str_Id, DocType, DocDate },
    { headers: authHeader() }
  );
};

const getVCdetails = (UserId, DocType) => {
  return axios.post(
    API_URL + "getvcdetails",
    { UserId, DocType },
    { headers: authHeader() }
  );
};

const getStoreList = (UserId) => {
  return axios.post(
    API_URL + "storetlist",
    { UserId },
    { headers: authHeader() }
  );
};

const getStoreMRRList = (UserId, Str_id, DocType, DocRI) => {
  return axios.post(
    API_URL + "storemrrlist",
    { UserId, Str_id, DocType, DocRI },
    { headers: authHeader() }
  );
};

const getPrintData = (docHeaderId, DocType, Str_Id_Ref) => {
  return axios.post(
    API_URL + "printdata",
    { docHeaderId, DocType, Str_Id_Ref },
    { headers: authHeader() }
  );
};

const getPrintItemData = (Doc_id) => {
  return axios.post(
    API_URL + "storeitemlist",
    { Doc_id },
    { headers: authHeader() }
  );
};

const getStoreRefData = (Str_id, UserId) => {
  return axios.post(
    API_URL + "getstorerefdata",
    { Str_id, UserId },
    { headers: authHeader() }
  );
};

const getLines = (Str_id) => {
  return axios.post(
    API_URL + "getlines",
    { Str_id },
    { headers: authHeader() }
  );
};

const mrrObject = {
  createMrr,
  getLatestSrNo,
  getVCdetails,
  getStoreList,
  getStoreMRRList,
  sentApproval,
  getPrintData,
  getPrintItemData,
  getStoreRefData,
  getLines,
};
export default mrrObject;
