import axios from "axios";
const user = JSON.parse(localStorage.getItem('user'));
const API_URL = process.env.REACT_APP_API_BASE_URL;
const upload = (file, Str_id, id, onUploadProgress) => {
  let formData = new FormData();
  formData.append("Str_id", Str_id);
  formData.append("id", id);
  formData.append("file", file);
  return axios.post(API_URL + "upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${user.access_token}`,
    },
    onUploadProgress,
  });
};

const getFiles = () => {
  return axios.get("/files");
};
const uploadObject = {
  upload,
  getFiles,
};
export default uploadObject;
